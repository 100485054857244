import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assetNotFound: {
    id: 'asset.notFound',
    defaultMessage: 'Asset not found',
  },
  assetDownload: {
    id: 'asset.download',
    defaultMessage: 'Download',
  },
  assetDownloadError: {
    id: 'asset.download.error',
    defaultMessage: 'Asset download failed.',
  },
});
