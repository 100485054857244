import { useIntl } from 'react-intl';
import React, { FC } from 'react';
import { LanguageProvider } from '@cld/intl';
import { Button } from '@cld/button';
import { loadMessages, messages } from '../../i18n';
import { ALL_SYSTEMS_OPERATIONAL_URL, getLink, SUPPORT_REQUEST_URL } from '../SomethingWentWrong/SomethingWentWrong.utils';
import { SomethingWentWrongLayout, SomethingWentWrongLayoutProps } from '../SomethingWentWrong/SomethingWentWrong';
import { StyledSomethingWentWrongVertical } from '../SomethingWentWrong/SomethingWentWrong.styled';
import { StyledLink } from './ServerError.styled';

type ServerErrorProps = SomethingWentWrongLayoutProps;

export const ServerError: FC<React.PropsWithChildren<ServerErrorProps>> = ({ className, ...rest }) => {
  const { formatMessage } = useIntl();
  return (
    <StyledSomethingWentWrongVertical className={className}>
      <SomethingWentWrongLayout
        imageSrc="https://res.cloudinary.com/prod/image/upload/v1680430652/mpex/serverError.svg"
        title={formatMessage(messages.serverErrorTitle)}
        subTitle={formatMessage(messages.serverErrorSubtitle, { strong: getLink(ALL_SYSTEMS_OPERATIONAL_URL) })}
        data-test="server-error"
        {...rest}
      >
        <StyledLink href={SUPPORT_REQUEST_URL} target="_blank" rel="noopener noreferrer">
          <Button>{formatMessage(messages.serverErrorButton)}</Button>
        </StyledLink>
      </SomethingWentWrongLayout>
    </StyledSomethingWentWrongVertical>
  );
};

const ServerErrorIntl: FC<React.PropsWithChildren<ServerErrorProps>> = (props) => (
  <LanguageProvider loadMessages={loadMessages}>
    <ServerError {...props} data-test-id="server-error" />
  </LanguageProvider>
);

export default ServerErrorIntl;
