import React, { FC, useCallback, ReactEventHandler } from 'react';
import { asStyledComponent } from '@clds/component-enhancer';
import { StyledImage } from './CustomerLogo.styled';

export interface CustomerLogoProps {
  /** logo image url */
  src: string;
  /** size of the logo image */
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'stretch';
  /** fallback url in case of errors */
  fallback?: string;
  /** onError handler */
  onError?: ReactEventHandler<HTMLImageElement>;
}

export const CustomerLogo: FC<React.PropsWithChildren<CustomerLogoProps>> = ({ src, size = 'stretch', fallback, onError, ...rest }) => {
  const handleError: ReactEventHandler<HTMLImageElement> = useCallback(
    (event) => {
      if (fallback) {
        const eventTarget = event.target as EventTarget & HTMLImageElement;
        eventTarget.onerror = null;
        eventTarget.src = fallback;
      }

      if (onError) {
        event.persist();
        onError(event);
      }
    },
    [onError, fallback]
  );

  return <StyledImage src={src} alt="logo" size={size} onError={handleError} data-test="logo" {...rest} />;
};

export default asStyledComponent(CustomerLogo);
