import { getProperties } from '@cld/console-apps-services';
import getEnvConfig from '@cld/env-config';
import 'whatwg-fetch';

import { parseData } from './parseData';

const buildRequestUrl = (customerId: string, assetId: string) => {
  const apiBaseUrl = getEnvConfig().apiBaseUrl;
  return `${apiBaseUrl}/console/api/v1/public_links/${customerId}/assets/${assetId}?mode=json`;
};

export const fetchData = async () => {
  const { customerId, resourceId: assetId } = getProperties();

  if (!(assetId && customerId)) {
    throw new Error('corrupted properties');
  }

  const requestUrl = buildRequestUrl(customerId, assetId);
  const response = await fetch(requestUrl);

  if (response.status >= 500) {
    throw new Error('delivery failure');
  }

  if (response.status >= 400) {
    throw new Error('asset not found');
  }

  return response.json() as Promise<unknown>;
};

export const getData = async () => {
  const data = await fetchData();
  return parseData(data);
};
