import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { usePhone } from '@clds/style-utils';
import { AssetMetadataVertical } from '@cld/asset-metadata';
import Button from '@cld/button';
import { Asset, VideoAsset } from '@cld/console-apps-types';
import CustomerLogo, { fromAccountId } from '@cld/customer-logo';
import { download } from '@cld/file-download';
import { Icon } from '@cld/icon';

import { messages } from '../i18n';

const Root = styled.header.attrs({ 'data-test': 'asset-share-header' })`
  display: flex;
  align-items: center;
  padding: ${globalTheme.spacing.md};
  background-color: ${globalTheme.color.white};

  ${CustomerLogo} {
    margin-right: ${globalTheme.spacing.md};
  }

  ${AssetMetadataVertical} {
    padding-left: ${globalTheme.spacing.md};
    border-left: 1px solid ${globalTheme.color.gray};
  }
`;

const Actions = styled.div`
  margin-left: auto;
`;

interface HeaderProps {
  asset: Asset;
  encryptedAccountId?: string;
}

export const Header: FC<React.PropsWithChildren<HeaderProps>> = ({ asset, encryptedAccountId }) => {
  const downloadAsset = () => asset.urls.originalDownload && download(asset.urls.originalDownload);
  const isPhone = usePhone();

  return (
    <Root data-test="header">
      <CustomerLogo src={fromAccountId(encryptedAccountId)} size="lg" />
      <AssetMetadataVertical
        type={asset.type}
        filename={asset.displayName || asset.filename}
        bytes={asset.bytes}
        height={(asset as VideoAsset).height}
        width={(asset as VideoAsset).width}
        duration={(asset as VideoAsset).duration}
        color="gray80"
        data-testid="asset-metadata"
      />
      {asset.urls.originalDownload && (
        <Actions>
          <Button onClick={downloadAsset} data-test-specifier="download-button" data-testid="download-button">
            <Icon name="fileDownload" />
            {!isPhone && <FormattedMessage {...messages.assetDownload} />}
          </Button>
        </Actions>
      )}
    </Root>
  );
};
