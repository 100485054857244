import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styled, { ThemeProvider } from 'styled-components';
import { generateTheme } from '@clds/theme-helper';
import { globalTheme } from '@clds/component-theme';
import Progress from '@clds/progress';
import { useTitleFromPromise } from '@cld/console-apps-services';
import { NotFound, WasDeleted } from '@cld/empty-state';
import Footer from '@cld/footer';
import GlobalStyles from '@cld/global-style';
import { LanguageProvider } from '@cld/intl';
import useCloudinaryFavicon from '@cld/use-cloudinary-favicon';
import { Asset, ResourceStatus, ResourceSubStatus } from '@cld/console-apps-types';
import { loadMessages, messages } from '../i18n';
import { Content } from './Content';
import { DataProvider } from './data/DataProvider';
import { useData } from './data/useData';
import { Header } from './Header';
import { MainLayout } from './MainLayout';

const Root = styled.div`
  background-color: ${globalTheme.color.white};
`;

const ProgressWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface AssetShareContentProps {
  asset: Asset;
  encryptedAccountId: string;
}

const AssetShareContent: FC<React.PropsWithChildren<AssetShareContentProps>> = ({ asset, encryptedAccountId }) => (
  <Root>
    <MainLayout footer={<Footer />} content={<Content asset={asset} />} header={<Header asset={asset} encryptedAccountId={encryptedAccountId} />} />
  </Root>
);

const AssetShare = () => {
  const { formatMessage } = useIntl();
  const notFoundMessage = formatMessage(messages.assetNotFound);

  const assetShareData = useData();
  const { isLoading, data, error } = assetShareData;

  useTitleFromPromise(assetShareData, (data) => data.asset.displayName || data.asset.filename, notFoundMessage);
  useCloudinaryFavicon();

  if (isLoading) {
    return (
      <ProgressWrapper>
        <Progress.Circular variant="primary" size="xl" />
      </ProgressWrapper>
    );
  }

  if (error || !data) {
    return <NotFound title={notFoundMessage} />;
  }

  const { status, subStatus } = data.asset;

  if (status === ResourceStatus.NotFound && subStatus === ResourceSubStatus.Deleted) {
    return <WasDeleted />;
  }

  return <AssetShareContent asset={data.asset} encryptedAccountId={data.customLogoPath} />;
};

const App = () => (
  <ThemeProvider theme={generateTheme()}>
    <DataProvider>
      <GlobalStyles />
      <LanguageProvider loadMessages={loadMessages}>
        <AssetShare />
      </LanguageProvider>
    </DataProvider>
  </ThemeProvider>
);

export default App;
