import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import Progress from '@clds/progress';
import { Asset, isRawAsset } from '@cld/console-apps-types';
import { NoPreview, ServerError } from '@cld/empty-state';
import { download } from '@cld/file-download';
import ImageFit from '@cld/image-fit';
import { useProgressiveImage } from '@cld/use-progressive-image';
import { withCheckerboardBackground } from '@cld/with-checkerboard-background';

const StyledImageWrapper = styled(ImageFit)`
  background-color: ${globalTheme.color.black};
`;

const StyledNoPreview = styled(NoPreview)`
  background-color: ${globalTheme.color.gray40};
  border-bottom: 1px solid ${globalTheme.color.gray50};
`;

const StyledServerError = styled(ServerError)`
  background-color: ${globalTheme.color.gray40};
  border-bottom: 1px solid ${globalTheme.color.gray50};
`;

const ProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

const StyledImage = withCheckerboardBackground(styled.img<{ isLoading: boolean }>`
  ${(props) =>
    props.isLoading &&
    css`
      display: none;
    `};
`);

interface ContentProps {
  asset: Asset;
}

export const Content: FC<React.PropsWithChildren<ContentProps>> = ({ asset }) => {
  const { isError, isLoading, src } = useProgressiveImage({
    highQualitySrc: asset.urls.preview,
  });

  if (isRawAsset(asset)) {
    const downloadAsset = () => asset.urls.originalDownload && download(asset.urls.originalDownload);
    return <StyledNoPreview action="Download" onActionClick={downloadAsset} data-testid="no-preview" />;
  }

  if (isError) {
    return <StyledServerError data-testid="server-error" />;
  }

  return (
    <StyledImageWrapper data-test="content">
      {isLoading && (
        <ProgressWrapper>
          <Progress.Circular variant="primary" size="xl" data-testid="progress-bar" />
        </ProgressWrapper>
      )}
      <StyledImage src={src} alt={asset.publicId} isLoading={isLoading} />
    </StyledImageWrapper>
  );
};
