import { createTheme, globalTheme } from '@clds/component-theme';

export const theme = createTheme({
  background: {
    color: {
      base: globalTheme.color.gray40,
    },
  },
  font: {
    color: {
      base: globalTheme.font.color.primary,
    },
  },
});
