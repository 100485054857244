import { normalizeAssetType, normalizeAssetFormat, validateAsset } from '@cld/console-apps-types';

import { AssetShareData } from './AssetShareData';
import { AssetShareDataDto, assetShareDataSchema } from './AssetShareData.dto';

export const parseData = (rawData: unknown): AssetShareData => {
  const dto: AssetShareDataDto = assetShareDataSchema.validateSync(rawData);

  const type = normalizeAssetType(dto.asset.resourceType, dto.asset.resourceSubtype);
  const format = normalizeAssetFormat(dto.asset.resourceType, dto.asset.publicId, dto.asset.format);
  const asset = validateAsset(dto.asset.uploadType, type, { ...dto.asset, format, type });

  return {
    asset,
    customLogoPath: dto.customLogoPath,
  };
};
