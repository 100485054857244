import React, { FC, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { useAnimation } from '@cld/use-animation';
import { typography } from '@clds/typography';
import { globalTheme } from '@clds/component-theme';

const AnimationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Animation = styled.div<{ width: number; height: number }>`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
`;

const Title = styled.div`
  margin-bottom: ${globalTheme.spacing.xs};
  ${typography.xl.regular};
`;

const Subtitle = styled.div`
  margin-bottom: ${globalTheme.spacing.xs};
  ${typography.sm.regular};
`;
export interface SearchNotFoundAnimationProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  height: number;
  width: number;
  subtitle?: ReactNode;
  title?: ReactNode;
}
const SearchNotFoundAnimation: FC<React.PropsWithChildren<SearchNotFoundAnimationProps>> = ({ children, width, height, title, subtitle, ...rest }) => {
  const { animationRef } = useAnimation({
    path: 'https://res.cloudinary.com/prod/raw/upload/v1693158378/lottie-animations/not-found/animation.json',
  });
  return (
    <AnimationContainer {...rest}>
      <Animation width={width} height={height} ref={animationRef} />
      {title && <Title data-test="empty-state-title">{title}</Title>}
      {subtitle && <Subtitle data-test="empty-state-subtitle">{subtitle}</Subtitle>}
      {children}
    </AnimationContainer>
  );
};

export default SearchNotFoundAnimation;
