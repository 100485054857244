import { ResourceType, ResourceSubtype, UploadTypeValues, ResourceStatus, ResourceSubStatus } from '@cld/console-apps-types';
import * as yup from '@cld/yup-extra';

export const assetShareDataSchema = yup
  .object({
    asset: yup
      .object({
        publicId: yup.string().required(),
        filename: yup.string().required(),
        externalId: yup.string().required(),
        displayName: yup.mixed().optional(),
        format: yup.string().nullable(),
        bytes: yup.number().required(),
        resourceType: yup.mixed().oneOf(Object.values(ResourceType)).required(),
        resourceSubtype: yup.mixed().oneOf([...Object.values(ResourceSubtype), null]),
        uploadType: yup.mixed().oneOf(UploadTypeValues).required(),
        urls: yup
          .object({
            original: yup.string().required(),
            originalDownload: yup.string().notRequired(),
            preview: yup.string().required(),
            previewDownload: yup.string().optional(),
            thumbnail: yup.string().required(),
            thumbnailDownload: yup.string().optional(),
          })
          .from('original_download', 'originalDownload')
          .required(),
        status: yup.mixed().oneOf(Object.values(ResourceStatus)).required(),
        subStatus: yup.mixed().oneOf([...Object.values(ResourceSubStatus), null]),
      })
      .from('delivery_urls', 'urls')
      .from('public_id', 'publicId')
      .from('resource_type', 'resourceType')
      .from('resource_subtype', 'resourceSubtype')
      .from('type', 'uploadType')
      .from('substatus', 'subStatus')
      .from('external_id', 'externalId')
      .from('display_name', 'displayName')
      .required(),
    customLogoPath: yup.string().required(),
  })
  .from('custom_logo_path', 'customLogoPath')
  .noUnknown()
  .required();

export type AssetShareDataDto = yup.InferType<typeof assetShareDataSchema>;
