import styled from 'styled-components';

import { globalTheme } from '@clds/component-theme';

import { theme, sizeVariant } from '../theme';
import { CustomerLogoProps } from './CustomerLogo';

export interface StyledImageProps {
  size: Exclude<CustomerLogoProps['size'], undefined>;
}

export const StyledImage = styled.img<StyledImageProps>`
  min-height: ${theme.minHeight};
  max-height: ${sizeVariant((variant) => variant.maxHeight)};
  max-width: ${sizeVariant((variant) => variant.maxWidth)};
  background-color: ${globalTheme.palette.contrastInvert};
`;
