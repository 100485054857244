import React, { FC, ReactNode, useMemo } from 'react';
import { usePromiseState } from '@cld/use-promise-state';

import { getData } from '../../services/dataService/dataService';
import { DataContext } from './DataContext';

export interface DataProviderProps {
  children?: ReactNode;
}

export const DataProvider: FC<React.PropsWithChildren<DataProviderProps>> = ({ children }) => {
  const getDataPromise = useMemo(() => getData(), []);
  const dataPromiseState = usePromiseState(getDataPromise);

  return <DataContext.Provider value={dataPromiseState}>{children}</DataContext.Provider>;
};
