import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { asStyledComponent } from '@clds/component-enhancer';

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

interface MainLayoutProps {
  header: ReactNode;
  content: ReactNode;
  footer: ReactNode;
}

export const MainLayout = asStyledComponent(({ header, content, footer }: MainLayoutProps) => (
  <Content data-test="layout">
    {header}
    {content}
    {footer}
  </Content>
));
