import { useIntl } from 'react-intl';
import React, { FC, ReactNode } from 'react';
import { LanguageProvider } from '@cld/intl';
import { ButtonLink } from '@cld/button';
import EmptyState, { CommonEmptyStateProps } from '../../EmptyState';
import { loadMessages, messages } from '../../i18n';
import wasDeleted from './wasDeleted.svg';

export interface WasDeletedProps extends CommonEmptyStateProps {
  /** Action button text */
  action?: ReactNode;
  /** Action button onClick handler */
  onActionClick?(): void;
}

export const WasDeleted: FC<React.PropsWithChildren<WasDeletedProps>> = ({ action, onActionClick, ...rest }) => {
  const { formatMessage } = useIntl();
  return (
    <EmptyState imageSrc={wasDeleted} imageHeight={113} imageWidth={99} title={formatMessage(messages.wasDeletedTitle)} {...rest}>
      {action && <ButtonLink onClick={onActionClick}>{action}</ButtonLink>}
    </EmptyState>
  );
};

const WasDeletedIntl: FC<React.PropsWithChildren<WasDeletedProps>> = (props) => (
  <LanguageProvider loadMessages={loadMessages}>
    <WasDeleted {...props} />
  </LanguageProvider>
);

export default WasDeletedIntl;
